import React from "react";

const ResumeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Resume</title>
    <path
      d="M8 6H16M8 10H16M8 14H11M6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22Z"
      stroke=""
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default ResumeIcon;
