import React from "react";
import { Hero, Work, Contact, About, Layout, Projects } from "../components";
import posthog from "posthog-js";

posthog.init("phc_RHdaeZI8AqncYQeYoJC3ZD5lMvxmGX7gNVO0rZmB1NS", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
});

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Work />
      <Projects />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
