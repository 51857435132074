import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Seo, GlobalStyle } from "../components";

const LayoutContainer = styled.div`
  background: var(--main-bg-color);
`;

const ContentViewport = styled.main`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2rem;
  @media screen and (max-width: 642px) {
    padding: 0 1.25rem;
  }
`;

const Layout = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    setScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <LayoutContainer>
      <Seo />
      <GlobalStyle />
      <ContentViewport>{children}</ContentViewport>
    </LayoutContainer>
  );
};

export default Layout;
